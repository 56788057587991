import { ReactNode } from 'react';

import HeroIcon from './HeroIcon';
import styles from './modal.module.scss';

type Props = {
  children: ReactNode;
  open: boolean;
  action: () => void;
};

const Modal = ({ children, open, action }: Props) => {
  return (
    <>
      {open && (
        <div className={`${styles.modalContainer} relative`} onClick={action}>
          <div
            className={`${styles.modal} w-full max-w-3xl mx-auto absolute top-1/4 left-1/2 transform -translate-x-1/2 `}
            onClick={(e) => e.stopPropagation()}
          >
            <div onClick={action}>
              <HeroIcon icon={'x'} extraClass={'absolute top-0 -right-32 z-50'} />
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
